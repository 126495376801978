import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Loader from "react-loader-spinner";
import withAuthorization from './withAuthorization';
import { db } from '../firebase';
import { onGetUserData } from '../actions/session';

import { KEY } from '../constants/routes';

class HomePage extends Component {
  componentDidMount() {
    this.props.onGetUserData(this.props.session.authUser.uid);
  }




  render() {
    const { session } = this.props;
    return <div className="admin-panel">
        {!session.loading ? <div className="section">
            <h1> Hi {session.user.firstName}</h1>
          </div> : <div className="loading">
            <Loader type="Grid" color="#008747" height={60} width={60} />
          </div>}
      </div>;
  }
}


const mapStateToProps = state => ({
  session: state.session,
});

const mapDispatchToProps = {
	onGetUserData,
};

const authCondition = (authUser) => !!authUser;

export default compose(
	withAuthorization(authCondition),
	connect(mapStateToProps, mapDispatchToProps)
)(HomePage);

