import { db } from './firebase';

import UUID from 'uuid/v1';

// User API

export const doCreateUser = (id, firstName, lastName, email) =>
	db.ref(`users/${id}`).set({
		firstName,
		lastName,
		email, 
	});

export const doCreateRefund = (id, fullname, accno, sortcode, email, date, amount, processed) => db
		.ref(`refunds/${id}`)
		.set({
			id,
			fullname,
			accno,
			sortcode,
			email,
			date,
			amount,
			processed,
		});

export const doCreateJob = (authorid, jobReference, jobTitle, jobLocation, jobSector, jobFunction, jobType, jobSalary, jobDescription, date, status) => {
	console.log('test')
	return db
           .ref("jobs/")
           .push()
           .set({
             authorid,
             jobReference,
             jobTitle,
             jobLocation,
             jobSector,
             jobFunction,
             jobType,
             jobSalary,
             jobDescription,
             date,
             status
		   })
		};
		   
export const doCreateSubscriber = (token) => db
	.ref(`subscribers/${token}`)
	.set({ token });


export const storeTickets = (id, tickets, answer, token) => {
	let promises = [];
	[...Array(tickets)].map(number => {
		return promises.push(new Promise((resolve, reject) => {
			return db.ref(`tickets/${UUID()}`).set({
				id,
				answer,
				token
			}).then((response) => {
				resolve(response)
			}, (response) => {
				return response;
			});
		}));
	});
	return Promise.all(promises)
}
	

export const onceGetUsers = () =>
	db.ref('users').once('value');

export const onceGetJobs = () =>
	db.ref('jobs').once('value');

export const onceGetSubscribers = () =>
	db.ref('subscribers').once('value');

export const onceGetUser = (id) =>
	db.ref(`users/${id}`).once('value');

export const onceGetRefunds = () =>
	db.ref('refunds').once('value');

export const onceGetTickets = () =>
	db.ref('tickets').once('value');

// Other Entity APIs ...