import React, { Component } from 'react';
class Privacy extends Component {
	render() {
		return (
			<div>
				<div className="header-img">
					<div className="nav-container">
						<div className="header-title"><h1>Privacy Policy</h1></div>
					</div>
				</div>
			<div className="container">
				<div className="row">
			<div className="col-xs-12">
				<div className="py-15">
				<p><span>Storey Huish Resourcing
				Limited</span><span>(company
				number </span><span>4848817</span><span
				>)
				('SHR', 'we', 'us' or 'our')</span><span
				>
				is a recruitment business focusing on positions within the intralogistics and
				material handling sectors. We provide professional recruitment services to
				clients looking to recruit personnel for their businesses and individuals
				looking to progress their careers.</span></p>

				<p><span>We are committed to
				protecting the privacy of our candidates, clients and users of our website. We
				want to provide a safe and secure user experience. We will ensure that the
				information you submit to us, or which we collect, via various channels
				(including our website, through written correspondence (including e-mail),
				conversations or meetings is only used for the purposes set out in this
				policy.&nbsp;&nbsp;</span></p>

				<p><span>Through
				this Privacy Policy we aim to inform you about the types of personal data we
				collect from candidates, the purposes for which we use the data and the ways in
				which the data is handled.&nbsp; We also aim to satisfy the obligation of
				transparency under the EU General Data Protection Regulation 2016/679 ('</span><b><span
				>GDPR</span></b><span
				>') and
				national laws implementing GDPR.You have a legal right to object at any time
				to:</span></p>

				<p><span>(i)
				use of your personal information for direct marketing purposes; and</span></p>

				<p><span>(ii)
				processing of your personal information which is based on our legitimate
				interests, unless there are compelling legitimate ground for our continued
				processing.</span></p>



				<p><span>&nbsp;For the purpose of this Privacy
				Policy the controller of personal data is Storey Huish Resourcing Limited and
				our contact details are set out at the end of this Privacy Policy. </span></p>



				<p><b><span>What does this policy cover?</span></b></p>

				<p><span>We at Storey Huish take your personal data
				seriously. This policy:</span></p>

				<ul>
				<li>sets
						out the types of personal data that we collect about you;</li>
				<li>explains
						how and why we collect and use your personal data;</li>
				<li>explains
						how long we keep your personal data;</li>
				<li>explains
						when, why and with who we will share your personal data;</li>
				<li>sets
						out the legal basis we have for using your personal data;</li>
				<li>explains
						the effect of refusing to provide the personal data requested;</li>
				<li>explains
						the different rights and choices you have when it comes to your personal
						data;</li>
				<li><span>explains
						how we may contact you and how you can contact us; and</span></li>
				<li><span>explains
						how we share your personal data.</span></li>
				</ul>



				<p><b><span>What personal data do we collect about
				you?</span></b></p>

				<p><span>We collect the
				information necessary to be able to find available opportunities and further
				information needed to assess your eligibility through the different stages of
				recruitment. This information includes CVs, identification documents,
				educational records, work history, employment and references.</span></p>

				<p><b><span>Where do we collect your personal
				information from?</span></b></p>

				<p><span>The following are the different sources we may
				collect personal data about you from:</span></p>

				<p><b><span>Directly from you</span></b><span>.
				This is information you provide while searching for a new opportunity and/or
				during the different recruitment stages,</span></p>



				<p><b><span>From an agent/third party acting on your behalf. </span></b><span
				>e.g. Interim Management Company.</span></p>



				<p><b><span>Through publicly available sources</span></b><span
				>. We use the following public sources: </span></p>



				<p><span>o<span>&nbsp;&nbsp;
				</span></span><span>LinkedIn </span></p>

				<p><span>o<span>&nbsp;&nbsp;
				</span></span><span>Job boards such as Monster, Totaljobs, Executives on the web
				etc</span></p>



				<p><b><span>By Reference or word of mouth</span></b><span>.
				For example, you may be recommended by a friend, a former employer, a former
				colleague or even a present employer.</span></p>


				<p><b><span>Howwe use your Personal Information</span></b></p>


				<p><span>As a niche recruitment
				company it is valuable for both you and us to hold basic information about you
				so that we can contact you about career opportunities in your specific industry
				sector.</span></p>

				<p><span>We use your personal
				data to match your skills, experience and education with a potential employer.
				We will initially collect basic information about you
				such as contact details, job role and experience, current salary details and
				salary expectations (often in the form of a CV and/or questionnaire or
				interview notes from a meeting/discussion).Only with your permission would we
				pass on information such as your CV, salary expectations etc to a client.</span></p>

				<p><span>If you are chosen by
				the client and go through to the next stage we will then be collecting more
				information from you at the interview (or equivalent) stage and onwards in that
				manner. If a client requests your personal contact details(e.g.address, email
				address, contact number), we will always request your permission before we
				forward such information. Usually this is so a client is able to communicate
				directly with you and provide details of a job offer or specific information
				relevant to the role you are applying for.</span></p>

				<p><span>If you are receiving
				coaching from us, we use your personal data in order to understand more about
				you and to assist you with understanding your skills and areas for improvement or
				future opportunities and options.</span></p>

				<p><span>We will hold, use and
				disclose your personal information, for our legitimate business purposes
				including:&nbsp;</span></p>

				<p><span>1.<span>
				</span></span><span>to
				provide our services to you;<br />
				&nbsp;</span></p>

				<p><span>2.<span>
				</span></span><span>to
				maintain our business relationship, where you are a user of our website, a client
				or candidate;<br />
				&nbsp;</span></p>

				<p><span>3.<span>
				</span></span><span>to
				enable you to submit your CV for general applications, to apply for specific
				jobs or to subscribe to our job alerts;<br />
				&nbsp;</span></p>

				<p><span>4.<span>
				</span></span><span>to
				match your details with job vacancies, to assist us in finding a position that
				is most suitable for you and to send your personal information (including
				sensitive personal information) to clients in order to apply for jobs;<br />
				&nbsp;</span></p>

				<p><span>5.<span>
				</span></span><span>to
				retain your details and notify you about future job opportunities other than
				the specific role for which you have contacted us;<br />
				&nbsp;</span></p>

				<p><span>6.<span>
				</span></span><span>to
				answer your enquiries;<br />
				&nbsp;</span></p>

				<p><span>7.<span>
				</span></span><span>to
				fulfil contractual obligations with our clients; and<br />
				<br />
				</span></p>

				<p><span>8.<span>
				</span></span><span>to
				release personal information to regulatory or law enforcement agencies, if we
				are required or permitted to do so.</span></p>



				<p><span>We
				may process, with your consent and in accordance with local regulations,
				certain sensitive personal data (known as special category data in GDPR) where
				you include it in information you send to us e.g. if you include information
				about your health, religion or ethnic origin in the CV you send to us.&nbsp; We
				may also be required to conduct a criminal records check against your
				details.&nbsp; We have processes in place to limit our use and disclosure of
				such sensitive data other than where permitted by law.</span></p>


				<p><b><span>How
				long we will hold your information</span></b></p>



				<p><span>The
				length of time we will hold or store your personal information for will depend
				on the services we perform for you and for how long you require these.&nbsp; As
				we often support candidates with placements over many years, and potentially
				throughout their careers, the purpose for which we retain candidate data is
				often an ongoing purpose.&nbsp; We conduct regular data-cleansing and updating
				exercises with our candidates to ensure that (a) the data that we hold is
				accurate and (b) we are not holding data for too long.</span></p>



				<p><span>Our standard terms of business state
				that our clients should retain candidate data such as CVs for the purposes of
				the specific role you apply for and that, as controllers of your data, they
				should inform you if they plan to retain your CV on file in order to notify you
				about potential future roles, or to otherwise hold or use your data for other
				purposes.</span></p>



				<p><b><span>Who
				do we share your Personal Information with?</span></b></p>



				<p><span>If
				you are a candidate your personal data maybe shared with a client who initiates
				a search for personnel. We may also conduct checks on you to verify the
				information you have provided and where we do this we may share your
				information with the client relevant to securing employment.</span></p>



				<p><span>If
				you are a client your personal data (such as name, job title and contact
				details) maybe shared with a candidate in order that both parties can
				communicate directly in relation to a job interview.</span></p>

				<p ><span>We may also share your personal
				data with third party service providers who perform functions on our behalf
				(including external consultants, business associates and professional advisors
				such as lawyers, auditors and accountants, technical support functions and IT
				consultants carrying out testing and development work on our business
				technology systems ,and third party outsourced IT, system administration and
				document storage providers where we have an appropriate processing agreement
				(or similar protections) in place).</span><span>We require minimum
				standards of confidentiality and data protection from such third parties.</span></p>

				<p ><span>&nbsp;</span></p>

				<p ><b><span>What</span></b><b><span>
				Legal Basis do we have for Processing your Personal Information</span></b></p>

				<p><span>Under
				GDPR, the main grounds that we rely upon in order to process personal
				information of clients and candidates are the following:&nbsp;</span></p>

				<p><span>(a)&nbsp;</span><b><span
				>Necessary for
				entering into, or performing, a contract</span></b><span>&nbsp; in order to perform obligations
				that we undertake in providing a service to you, or in order to take steps at
				your request to enter into a contract with us, it will be necessary for us to
				process your personal data.&nbsp;</span><span>For clients, we may rely on our
				processing being necessary to perform a contract for you, for example in
				contacting you.</span></p>



				<p><span>(b)&nbsp;</span><b><span
				>Necessary for
				compliance with a legal obligation</span></b><span>&nbsp; we are subject to certain legal
				requirements which may require us to process your personal data.&nbsp; &nbsp;We
				may also be obliged by law to disclose your personal data to a regulatory body
				or law enforcement agency.</span></p>



				<p><span>(c)&nbsp;</span><b><span
				>Necessary
				for the purposes of legitimate interests</span></b> - <span>For prospective candidates, interim
				managers, referees and clients, our processing is necessary for our legitimate
				interests as we need the information to be able to assess suitability for
				potential roles, to find potential candidates and to contact clients and
				referees. </span><span>&nbsp;</span></p>

				<p><span>(d)&nbsp;</span><b><span
				>Consent&nbsp;</span></b><span
				>
				in some circumstances, we may ask for your consent to process your personal
				data in a particular way.&nbsp;</span><span>If you are shortlisted as a
				candidate, then this may involve the processing of more detailed personal data
				including sensitive data such as health information that you or others provide
				about you. In that case we always ask for your explicit consent before
				undertaking such processing.</span></p>



				<p><span>Do we transfer your
				data outside the EEA?</span></p>

				<p><span>All information you
				provide to us is stored in secure servers located in the EU. </span></p>

				<p><span>To better match your
				employee profile with current opportunities we may transfer your personal data
				to clientsand partners in countries outside the EEA. These countries privacy
				laws may be different from those in your home country. Where we transfer data
				to a country which has not been deemed to provide adequate data protection
				standards we always have security measures and approved model clauses in place
				to protect your personal data. To find out more about how we safeguard your
				information as related to transfers contact us on
				charles.storey@storeyhuish.co.uk</span></p>

				<p><span>To
				the extent that any personal information is provided to third parties outside
				the EEA, or who will access the information from outside the EEA, we will
				ensure that approved safeguards are in place, such as the approved Model
				Clauses or the EU/US Privacy Shield.</span></p>

				<p><span><br />
				&nbsp;</span></p>

				<p><span>Regulatory
				and Law Enforcement Agencies</span></p>

				<p><span>If
				we receive a request from a regulatory body or law enforcement agency, and if permitted
				under GDPR and other laws, we may disclose certain personal information to such
				bodies or agencies.</span></p>





				<p><b><span>What
				happens if you do not provide us with the information we request or ask that we
				stop processing your information?</span></b></p>


				<p><span>If
				you do not provide the personal data necessary, or withdraw your consent for
				the processing of your personal datawe may not be able to match you with
				available job opportunities.</span></p>



				<p><b><span>Do we use Cookies to collect personal
				data on you?</span></b></p>

				<p><span>To
				provide better service to you on our websites, we may cookies to collect your
				personal data when you browse. See our Cookie Policy on our website.</span></p>



				<p><b><span>What
				rights do you have in relation to the Information we hold about you?</span></b></p>


				<p><span
				>By
				law, you have a number of rights when it comes to your personal data. </span><span
				>Further
				information and advice about your rights can be obtained from the data
				protection regulator in your country<span><span
				>.</span></span></span></p>

				<p><span
				>1.
				<b>The right to be informed:</b> You have the right to be provided with clear,
				transparent and easily understandable information about how we use your
				information and your rights. This is why were providing you with the
				information in this Policy.</span></p>

				<p><span>2<b>. The right of access:</b>You have the right to
				obtain access to your information (if were processing it), and certain other
				information (similar to that provided in this Privacy Policy). This is so
				youre aware and can check that were using your information in accordance with
				data protection law</span></p>

				<p><b><span
				>3.
				The right to rectification: </span></b><span>You are entitled to have your
				information corrected if its inaccurate or incomplete.</span></p>

				<p><b><span
				>4.
				The right to erasure:</span></b><span>This is also known as the right to be
				forgotten and, in simple terms, enables you to request the deletion or removal
				of your information where theres no compelling reason for us to keep using it.
				This is not a general right to erasure; there are exceptions.</span></p>

				<p><b><span
				>5.
				The right to restrict processing:</span></b><span>You have rights to
				block or suppress further use of your information. When processing is
				restricted, we can still store your information, but may not use it further. We
				keep lists of people who have asked for further use of their information to be
				blocked to make sure the restriction is respected in future.</span></p>

				<p><b><span
				>6.
				The right to data portability</span></b><span>: You have rights to
				obtain and reuse your personal data for your own purposes across different
				services. For example, if you decide to switch to a new provider, this enables
				you to move, copy or transfer your information easily between our IT systems
				and theirs safely and securely, without affecting its usability.</span></p>

				<p><b><span
				>7.
				The right to object to processing:</span></b><span>You have the right to
				object to certain types of processing, including processing for direct
				marketing (i.e. if you no longer want to be contacted with potential
				opportunities).</span></p>

				<p><b><span
				>8.
				The right to lodge a complaint: </span></b><span>You have the right to
				lodge a complaint about the way we handle or process your personal data with
				your national data protection regulator.</span></p>

				<p><b><span
				>9.
				The right to withdraw consent:</span></b><span> If you have given your
				consent to anything we do with your personal data, you have the right to
				withdraw your consent at any time (although if you do so, it does not mean that
				anything we have done with your personal data with your consent up to that
				point is unlawful). This includes your right to withdraw consent to us using
				your personal data for marketing purposes.</span></p>

				<p style={{marginBottom:'7.5pt'}}><span>We usually act on
				requests and provide information free of charge, but may charge a reasonable
				fee to cover our administrative costs of providing the information for: </span></p>

				<p><span>baseless or excessive/repeated requests, or</span></p>

				<p><span>further copies of the same information.</span></p>

				<p style={{marginBottom:'12.0pt'}}><span>Alternatively, we may
				be entitled to refuse to act on the request. </span></p>

				<p style={{marginBottom:'12.0pt'}}><span>Please consider your
				request responsibly before submitting it. Well respond as soon as we can.
				Generally, this will be within one month from when we receive your request but,
				if the request is going to take longer to deal with, well come back to you and
				let you know.</span></p>

				<p><span style={{fontSize: '9.0pt', fontFamily: '"Trebuchet MS",sans-serif', color:'red'}}>&nbsp;</span></p>

				<p><b><span>Complaints</span></b></p>



				<p><span>If
				you are unhappy about our use of your personal information, you can contact us
				using the details in the&nbsp;</span><a
				href="https://www.michaelpage.co.uk/privacy-policy#contact"><b><span
				style={{fontSize: '9.0pt', fontFamily: '"Trebuchet MS",sans-serif', color:'red', textDecoration: 'none'}}>Contact section</span></b></a><span>&nbsp;below. You are also entitled
				to lodge a complaint with the UK Information Commissioner's Office using any of
				the below contact methods:</span></p>



				<p><b><span>Telephone:</span></b><span
				>&nbsp;0303 123
				11113</span></p>



				<p><b><span>Website:</span></b><span
				>&nbsp;</span><a
				href="https://ico.org.uk/concerns/" target="_blank" rel="noopener noreferrer"><b><span style={{fontSize: '9.0pt', fontFamily: '"Trebuchet MS",sans-serif', color:'red', textDecoration: 'none'}}>https://ico.org.uk/concerns/</span></b></a></p>

				<p><span>&nbsp;</span><b><span
				>Post:</span></b></p>

				<p><span>&nbsp;Information
				Commissioner's Office</span></p>

				<p><span>Wycliffe
				House</span></p>

				<p><span>Water
				Lane</span></p>

				<p><span>Wilmslow</span></p>

				<p><span>Cheshire</span></p>

				<p><span>SK9
				5A</span></p>

				<p><b><span>Internet-based
				transfers</span></b></p>



				<p><span>Given
				that the Internet is a global environment, using the Internet to collect and
				process personal data necessarily involves the transmission of data on an
				international basis.&nbsp; &nbsp;Unfortunately the transmission of information
				via the internet is not completely secure. Although we will do our best to
				protect your personal data, we cannot guarantee the security of your data
				transmitted to our website via third party networks; any transmission is at
				your own risk. Once we have received your information, we will use strict
				procedures and security features to try to prevent unauthorised access.</span></p>



				<p><b><span>Changes
				to our Privacy Policy</span></b></p>



				<p><span>This
				privacy policy can be changed by SHR at any time. If we change our privacy
				policy in the future, we will advise you of material changes or updates to our
				privacy policy by email, where you have provided us with your email
				address.&nbsp;</span></p>



				<p style={{marginBottom:'12.0pt'}}><b><span>How will we contact
				you?</span></b></p>

				<p style={{marginBottom:'12.0pt'}}><span>We may contact you by
				phone, email or social media. If you prefer a particular contact means over
				another please just let us know</span></p>

				<p><b><span>How
				can you contact us?</span></b></p>



				<p><span>If
				you have any enquires or if you would like to contact us about our processing
				of your personal information, including to exercise your rights as outlined
				above, please contact us either by email to the following address:-</span></p>



				<p><strong>Email:</strong> charles.storey@storeyhuish.co.uk</p>



				<p><span>Storey
				Huish Resourcing Limited</span></p>



				<p><span>If
				you have a complaint please contact us either by email to the following
				address:-</span></p>



				<p><b><span>Contact
				name:&nbsp;</span></b><span>Charles
				Storey</span></p>



				<p><strong>Email:</strong> charles.storey@storeyhuish.co.uk</p>


				<p><span>When
				you contact us, we will ask you to verify your identity.&nbsp;</span></p>



				<p><span>Our
				registered office is at:</span><span>Suite
				1A, Realtex House, Leeds Road, Rawdon, Leeds, West Yorkshire. LS196AX</span></p>
				</div>
			</div>
			</div>
			</div>
			</div>
		)
	}
}

export default Privacy;