import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import {
  faBars,
  faPhone,
  faEnvelope,
  faHome,
  faPlus,
  faSignOutAlt,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { auth } from '../firebase';
import * as routes from '../constants/routes';

import SideNav, {
  NavItem,
  NavIcon,
  NavText
} from "@trendmicro/react-sidenav";
import logowhite from "../img/logo-white.png";


class Navigation extends Component {
	constructor(props) {
		super(props);
    this.toggleNav = this.toggleNav.bind(this);
		this.signOut = this.signOut.bind(this);
		this.state = {
      navOpen: false,
		}
		this.defaultState = this.state;
	}


  toggleNav = () => {
		this.setState({ navOpen: !this.state.navOpen })
	}

	signOut() {
    auth.doSignOut();
		this.setState(this.defaultState);
	}

	render () {
    const { navCollapsed } = this.state;
    if (this.props.location.pathname === '/sign-in') {
      return (
        <div></div>
      )
    }
    return <div>
        <div className="navbar top-bar">
          <div className="nav-container flex-wrap">
            <Link to={routes.HOME} className="logo">
              <img src={logowhite} alt="Storey Huish" height="100%" />
            </Link>
            <div className="tel-number">
              <FontAwesomeIcon icon={faPhone} style={{ marginRight: "10px" }} />
              <a href="tel:01423 611080">01423 611080</a>
              <a href="mailto:charles.storey@storeyhuish.co.uk">
                <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: "30px", marginRight: "10px" }} />
              </a>
            </div>
          </div>
        </div>

            {this.props.authUser ? <NavigationAuth location={this.props.location} history={this.props.history} onToggleNav={this.toggleNav} navOpen={this.state.navOpen} authUser={this.props.authUser} navCollapsed={navCollapsed} signOut={this.signOut} /> :
          <React.Fragment>
            {!this.props.holdingPage &&
          <nav className="navbar bottom-bar">
            <div className="nav-container">
              <span className="navbar-toggle" onClick={this.toggleNav}>
                <FontAwesomeIcon icon={faBars} />
              </span>
                <NavigationNonAuth navOpen={this.state.navOpen} onToggleNav={this.toggleNav} navCollapsed={navCollapsed} holdingPage={this.props.holdingPage}/>
            </div>
          </nav>
          }
          </React.Fragment>

      }
      </div>
          ;
	}

}

Navigation.contextTypes = {
	authUser: PropTypes.object,
	users: PropTypes.object,
};

const NavigationAuth = ({
  signOut,
  location,
  history
}) => (
  <SideNav
    className="sidenav-admin"
    onSelect={selected => {
      if (selected === "logout") {
        return signOut()
      }
      const to = "/" + selected;
      if (location.pathname !== to) {
        history.push(to);
      }
    }}
    onToggle={state =>
      state
        ? document.body.classList.add("menuOpen")
        : document.body.classList.remove("menuOpen")
    }
  >
    <SideNav.Toggle />
    <SideNav.Nav defaultSelected="home">
      <NavItem eventKey="home">
        <NavIcon>
          <FontAwesomeIcon icon={faHome} />
        </NavIcon>
        <NavText>Home</NavText>
      </NavItem>

        <NavItem eventKey="Add">
          <NavIcon>
            <FontAwesomeIcon icon={faPlus} />
          </NavIcon>
          <NavText>Add</NavText>
          <NavItem eventKey="add/job">
            <NavText>Job</NavText>
          </NavItem>
          <NavItem eventKey="add/news">
            <NavText>News</NavText>
          </NavItem>
        </NavItem>
        <NavItem eventKey="currentjobs">
          <NavIcon>
              <FontAwesomeIcon icon={faUsers} />
          </NavIcon>
          <NavText>Jobs</NavText>
        </NavItem>
        <NavItem eventKey="logout">
          <NavIcon>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </NavIcon>
          <NavText>Logout</NavText>
        </NavItem>
    </SideNav.Nav>
  </SideNav>
);

const NavigationNonAuth = ({ navOpen, navCollapsed, onToggleNav, holdingPage}) => {
	return <React.Fragment>
      <ul className={`main-nav ${navOpen ? "active" : ""}`}>
        <li>
        <Link className="nav-links" to={routes.LANDING} onClick={onToggleNav}>
            HOME
            </Link>
        </li>
        {!holdingPage &&
          <React.Fragment>
            <li>
            <Link className="nav-links" to={routes.OUR_SERVICES} onClick={onToggleNav}>
                OUR SERVICES
              </Link>
            </li>
            <li>
            <Link className="nav-links" to={routes.JOBS} onClick={onToggleNav}>
                JOBS
              </Link>
            </li>
            <li>
            <Link className="nav-links" to={routes.WHO_WE_ARE} onClick={onToggleNav}>
                WHO WE ARE
              </Link>
            </li>
            <li>
            <Link className="nav-links" to={routes.CLIENTS} onClick={onToggleNav}>
                CLIENTS
              </Link>
            </li>
            <li>
            <Link className="nav-links" to={routes.CONTACT} onClick={onToggleNav}>
                CONTACT
              </Link>
            </li>
          </React.Fragment>
        }
      </ul>
      {!holdingPage &&
        <Link to="/sign-in" className="login-button" type="button" onClick={onToggleNav}>LOGIN</Link>
      }
    </React.Fragment>;
}

const mapStateToProps = (state) => ({
	authUser: state.session.authUser
});

export default withRouter(connect(mapStateToProps)(Navigation));