import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from './withAuthorization';
import RichTextEditor from "react-rte";
import { db } from "../firebase";
import { KEY } from "../constants/routes";
class AddJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobReference: "",
      jobTitle: "",
      jobLocation: "",
      jobSector: "",
      jobFunction: "",
      jobType: "",
      jobSalary: "",
      jobDescription: RichTextEditor.createEmptyValue()
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onHandleChange(key, value) {
    console.log(value);
    this.setState({ [key]: value });
  }

  onChange(value) {
    this.setState({ jobDescription: value });
  }

  formatDate(currentdate) {
    return (
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds()
    );
  }

  async createJob(format) {
    const date = this.formatDate(new Date());
    if (format === "publish") {
      const subscribers = await db
        .onceGetSubscribers()
        .then(subscriber => subscriber.val());
      Object.keys(subscribers).map(subscriber => {
        return fetch("https://fcm.googleapis.com/fcm/send", {
          method: "POST", // or 'PUT'
          body: JSON.stringify({
            notification: {
              title: "Storey Huish",
              body: "New job posted at www.storeyhuish.co.uk",
              click_action: "http://www.storeyhuish.co.uk/jobs",
              icon: "http://www.storeyhuish.co.uk/icon.png"
            },
            to: subscribers[subscriber].token
          }), // data can be `string` or {object}!
          headers: {
            "Content-Type": "application/json",
            Authorization: `key=${KEY}`
          }
        })
          .then(response => console.log("Success:", JSON.stringify(response)))
          .catch(error => console.error("Error:", error));
      });
    }
    console.log(this.props.session.authUser.uid);
    db.doCreateJob(
      this.props.session.authUser.uid,
      this.state.jobReference,
      this.state.jobTitle,
      this.state.jobLocation,
      this.state.jobSector,
      this.state.jobFunction,
      this.state.jobType,
      this.state.jobSalary,
      this.state.jobDescription.toString("html"),
      date,
      format
    );
  }

  render() {
    console.log(this.state);
    return <div className="admin-panel justify-center">
        <div className="leftFiller" />
        <div className="section section-half">
          <h1>Add new Job</h1>
          <form className="form-card">
            <fieldset className="form-fieldset">
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Copy your assignment form Invenias" type="input" required onChange={evt => this.onHandleChange("jobReference", evt.target.value)} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Assignment Reference
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Enter a job title" type="input" required onChange={evt => this.onHandleChange("jobTitle", evt.target.value)} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Job title
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Enter a location " type="input" required onChange={evt => this.onHandleChange("jobLocation", evt.target.value)} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Location
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Enter a sector " type="input" required onChange={evt => this.onHandleChange("jobSector", evt.target.value)} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Sector
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Enter a job function " type="input" required onChange={evt => this.onHandleChange("jobFunction", evt.target.value)} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Job Function
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Enter a job type " type="input" required onChange={evt => this.onHandleChange("jobType", evt.target.value)} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Job Type
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-x98ezh-s6s2g8-vfrkgb-ngrhef-atfkop" className="form-element-field -hasvalue" placeholder="Enter a salary" type="number" required onChange={evt => this.onHandleChange("jobSalary", evt.target.value)} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-x98ezh-s6s2g8-vfrkgb-ngrhef-atfkop">
                  Salary
                </label>
                {/*<small className="form-element-hint">You are way to young, sorry</small>*/}
              </div>
              <div className="form-element form-textarea">
                <RichTextEditor value={this.state.jobDescription} onChange={this.onChange} />

                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-3naeph-0f3yuw-x153ph-dzmahy-qhkmgm">
                  Job description
                </label>
              </div>
            </fieldset>
            <div className="form-actions">
              <button className="form-btn-cancel -nooutline" type="button" onClick={() => this.createJob("draft")}>
                Save Draft
              </button>
              <button className="form-btn -nooutline" type="button" onClick={() => this.createJob("publish")}>
                Publish
              </button>
            </div>
          </form>
        </div>
      </div>;
  }
}


const mapStateToProps = state => ({
  session: state.session,
});

const mapDispatchToProps = {
};

const authCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(AddJob);
