import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { compose } from "recompose";
import { onGetJobs } from '../actions/jobs';
class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cv: "",
      isUploading: false,
      progress: 0,
      cvURL: ""
    };
  }

  componentDidMount() {
    this.props.onGetJobs();
  }


  render() {
    console.log(this.state);
    return (
      <div>
        <div className="header-img">
          <div className="nav-container">
            <div className="header-title">
              <h1>Jobs</h1>
            </div>
          </div>
        </div>
        <div className="container" style={{ paddingBottom: "15px" }}>
          <div className="row">
            {!this.props.jobs.loading &&
            Object.keys(this.props.jobs.data).length !== 0 ? (
              Object.keys(this.props.jobs.data).map((item, i) => {
                return <div key={i} className="col-xs-12 col-md-6">
                      <div className="row job-card">
                        <div className="col-xs-12">
                          <dl>
                            <dt>Job title:</dt>
                            <dd>
                              {this.props.jobs.data[item].jobTitle}
                            </dd>
                            <dt>Job sector:</dt>
                            <dd>
                              {this.props.jobs.data[item].jobSector}
                            </dd>
                            <dt>Job location:</dt>
                            <dd>
                              {
                                this.props.jobs.data[item]
                                  .jobLocation
                              }
                            </dd>
                            <dt>Job type:</dt>
                            <dd>
                              {this.props.jobs.data[item].jobType}
                            </dd>
                            <dt>Job function:</dt>
                            <dd>
                              {
                                this.props.jobs.data[item]
                                  .jobFunction
                              }
                            </dd>
                            <dt>Job salary:</dt>
                            <dd>
                              {this.props.jobs.data[item].jobSalary}
                            </dd>
                          </dl>
                        </div>
                        <div className="col-xs-12">
                          <dl className="column">
                            <dt>Description</dt>
                            <dd className="column-description" dangerouslySetInnerHTML={{ __html: this.props.jobs.data[item].jobDescription }} />
                          </dl>
                        </div>
                        <div className="col-xs-12 text-center">
                        <Link to={`./jobs/${item}`} className="uk-button uk-button-primary">Read more and apply</Link>
                        </div>
                      </div>
                    </div>;
              })
            ) : (
              <div>No data</div>
            )}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
	jobs: state.jobs,
});

const mapDispatchToProps = {
	onGetJobs,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps)
)(Jobs);
