import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";

import { onGetJobs } from "../actions/jobs";

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
    	job: {},
    };
  }
  componentDidMount() {
    this.props.onGetJobs();
  }

  componentWillReceiveProps(nextProps) {
    const jobID = Object.keys(nextProps.jobs.data).filter(
      job => job === nextProps.match.params.id
    );
    this.setState({ job: nextProps.jobs.data[jobID] });
  }

  render() {
    return (
      <div>
        <div className="header-img">
          <div className="nav-container">
            <div className="header-title">
				<h1>{this.state.job && this.state.job.jobTitle && this.state.job.jobTitle}</h1>
            </div>
          </div>
        </div>
        <div className="container">
		  <div className="row">
			{this.state.job ?
			<div className="col-xs-12">
				<div className="row job-card mb-md">
					<div className="col-xs-12">
						<dl>
							<dt>Job title:</dt>
							<dd>
								{this.state.job.jobTitle}
							</dd>
							<dt>Job sector:</dt>
							<dd>
								{this.state.job.jobSector}
							</dd>
							<dt>Job location:</dt>
							<dd>
								{
									this.state.job
										.jobLocation
								}
							</dd>
							<dt>Job type:</dt>
							<dd>
								{this.state.job.jobType}
							</dd>
							<dt>Job function:</dt>
							<dd>
								{
									this.state.job
										.jobFunction
								}
							</dd>
							<dt>Job salary:</dt>
							<dd>
								{this.state.job.jobSalary}
							</dd>
						</dl>
					</div>
					<div className="col-xs-12">
						<dl className="column">
							<dt>Description</dt>
							<dd dangerouslySetInnerHTML={{ __html: this.state.job.jobDescription }} />
						</dl>
					</div>
					<div className="col-xs-12 text-center">
									<Link to={`./${this.props.match.params.id}/apply`} className="uk-button uk-button-primary">Apply</Link>
					</div>
				</div>
			</div> 
			:
			null}
		  </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.jobs
});

const mapDispatchToProps = {
  onGetJobs
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(JobDetails);