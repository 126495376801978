import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from './withAuthorization';
class AdminJobs extends Component {
  render() {
    return <div className="admin-panel">
        <div className="section">
          <h1>Current Jobs</h1>
        </div>
      </div>;
  }
}


const mapStateToProps = state => ({
  session: state.session,
});

const mapDispatchToProps = {
};

const authCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(AdminJobs);
