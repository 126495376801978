import React from 'react';
import {
  withRouter
} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

import Navigation from './Navigation';
import AppRouter from "./AppRouter";
import Footer from './Footer';

import withAuthentication from './withAuthentication';

const App = () => (
  <div className="app__wrapper">
      <Navigation holdingPage={true}/>
      <AppRouter holdingPage={true}/>
      <Footer holdingPage={true}/>
      <CookieConsent
        location="bottom"
        buttonText="ACCEPT"
        cookieName="storey-huish-cookie"
        style={{ background: "#222", color: "#c5c5c5" }}
        buttonStyle={{ color: "#fff", fontSize: "14px", background: "#008747", padding: "5px 15px"}}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
  </div>
);

export default withRouter(withAuthentication(App));