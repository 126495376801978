import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {

	render() {
		return (
			<footer>
				<div className="container">
					<p className="m-0 text-white">Copyright &copy;Storey Huish</p>
					<p className="float-right"><Link to="/privacy">Privacy</Link></p>
				</div>
			</footer>
		)
	}
}

export default Footer;