

import {
	FETCH_JOBS,
	FETCH_JOBS_SUCCESS,
	FETCH_JOBS_ERROR
} from '../constants';

const INITIAL_STATE = {
	loading: false,
	data: {},
	error: null,
};

function jobsReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
    case FETCH_JOBS:
      return { ...state, loading: true, error: null };
    case FETCH_JOBS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_JOBS_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
}

export default jobsReducer;