import React from 'react';
import {
	Route,
	Switch,
} from 'react-router-dom';

// import Footer from "./Footer";
import Landing from "./Landing";
import SignInPage from "./SignIn";
import PasswordForgetPage from "./PasswordForget";
import HomePage from "./Home";
import AccountPage from "./Account";
import Contact from "./Contact";
import SignUp from "./SignUp";
import OurServices from "./OurServices";
import Clients from "./Clients";
import Jobs from "./Jobs";
import About from "./About";
import AddJob from "./AddJob";
import AddNews from "./AddNews";
import AdminJobs from "./AdminJobs";
import NotFound from "./NotFound";
import JobDetails from "./JobDetails";
import HoldingPage from './HoldingPage';
import Apply from "./Apply";
import Privacy from "./Privacy";
// import * as routes from "../constants/routes";

const AppRouter = (props) => (
  <Switch>
    {props.holdingPage ?
      <Route exact path="/" component={HoldingPage} />
    :
      <React.Fragment>
        <Route exact path="/" component={Landing} />
        <Route exact path="/sign-in" component={SignInPage} />
        <Route exact path="/pw-forget" component={() => <PasswordForgetPage />} />
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/add/job" component={AddJob} />
        <Route exact path="/add/news" component={AddNews} />
        <Route exact path="/my-account" component={AccountPage} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/jobs" component={Jobs} />
        <Route exact path="/jobs/:id" component={JobDetails} />
        <Route exact path="/jobs/:id/apply" component={Apply} />
        <Route exact path="/currentjobs" component={AdminJobs} />
        <Route exact path="/about" component={About} />
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/our-services" component={OurServices} />
        <Route exact path="/sign-up" component={SignUp} />

      </React.Fragment>
    }
    <Route exact path="/privacy" component={Privacy} />
    <Route exact path="*" component={NotFound} />
  </Switch>
);

export default AppRouter