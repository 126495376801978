import React, { Component } from 'react';
class Contact extends Component {
	render() {
		return (
			<div>
				<div className="header-img">
					<div className="nav-container">
						<div className="header-title">
							<h1>Contact Us</h1>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						content
					</div>
				</div>
			</div>
		)
	}
}

export default Contact;