import React, { Component } from 'react';
class OurServices extends Component {
	render() {
		return (
			<div>
				<div className="header-img holding-page">
					<div className="nav-container">
						<div className="header-title"><h1>New Website Coming Soon!</h1></div>
					</div>
				</div>
			</div>
		)
	}
}

export default OurServices;