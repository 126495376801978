import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import { onGetJobs } from "../actions/jobs";
class Jobs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cv: "",
			isUploading: false,
			progress: 0,
			cvURL: "",
			job: {},
			title: "",
			firstName: "",
			lastName: "",
			email: "",
			message: "",
		};
		this.applyForJob = this.applyForJob.bind(this);
	}

	componentDidMount() {
		this.props.onGetJobs();
	}

	onHandleChange(key, value) {
		console.log(value);
		this.setState({ [key]: value });
	}

	componentWillReceiveProps(nextProps) {
		const jobID = Object.keys(nextProps.jobs.data).filter(job => job === nextProps.match.params.id);
		this.setState({ job: nextProps.jobs.data[jobID]});
	}

	handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
	handleProgress = progress => this.setState({ progress });
	handleUploadError = error => {
		this.setState({ isUploading: false });
		console.error(error);
	};
	handleUploadSuccess = filename => {
		this.setState({ cv: filename, progress: 100, isUploading: false });
		firebase
			.storage()
			.ref("cvs")
			.child(filename)
			.getDownloadURL()
			.then(url => this.setState({ cvURL: url }));
	};

	applyForJob(evt){
		evt.preventDefault();
		console.log(this.state)
	}

	render() {
		const isValid = this.state
		return <div>
        <div className="header-img">
          <div className="nav-container">
            <div className="header-title">
              <h1>
                Application for <br />
                {this.state.job && this.state.job.jobTitle && this.state.job.jobTitle}
              </h1>
            </div>
          </div>
        </div>
			<div className="container" style={{ paddingBottom: "15px" }}>
				<form className="form-card" onSubmit={(evt) => this.applyForJob(evt)}>
            <fieldset className="form-fieldset">
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Copy your assignment form Invenias" type="input" required onChange={evt => this.onHandleChange("title", evt.target.value.trim())} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Title
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Copy your assignment form Invenias" type="input" required onChange={evt => this.onHandleChange("firstName", evt.target.value.trim())} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  First name
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Enter a job title" type="input" required onChange={evt => this.onHandleChange("lastName", evt.target.value.trim())} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Surname
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Enter a location " type="input" required onChange={evt => this.onHandleChange("email", evt.target.value.trim())} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Email
                </label>
              </div>
              <div className="form-element form-input">
                <input id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9" className="form-element-field" placeholder="Enter a sector " type="tel" required onChange={evt => this.onHandleChange("telephone", evt.target.value.trim())} />
                <div className="form-element-bar" />
                <label className="form-element-label" htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9">
                  Telephone
                </label>
              </div>
				<div className="form-element form-textarea">
							<textarea id="field-3naeph-0f3yuw-x153ph-dzmahy-qhkmgm" className="form-element-field" rows="3" placeholder="Message" onChange={evt => this.onHandleChange("message", evt.target.value.trim())}/>
					<div className="form-element-bar" />
					<label className="form-element-label" htmlFor="field-3naeph-0f3yuw-x153ph-dzmahy-qhkmgm">
						Message
                	</label>
					</div>
			<div className="file-wrapper">
				{this.state.isUploading && <p>Progress: {this.state.progress}</p>}
				{this.state.cvURL && <a href={this.state.cvURL}>Donwnload CV</a>}
				<FileUploader accept=".doc,.docx" name="cv" randomizeFilename storageRef={firebase
					.storage()
					.ref(
						"cvs"
					)} onUploadStart={this.handleUploadStart} onUploadError={this.handleUploadError} onUploadSuccess={this.handleUploadSuccess} onProgress={this.handleProgress} />
			  </div>
            </fieldset>
            <div className="form-actions">
              <button disabled={!this.state.cvURL} className="form-btn -nooutline" type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>;
	}
}

const mapStateToProps = (state) => ({
	jobs: state.jobs,
});

const mapDispatchToProps = {
	onGetJobs,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps)
)(Jobs);
