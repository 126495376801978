import React, { Component } from 'react';
class OurServices extends Component {
	render() {
		return (
			<div>
				<div className="header-img">
					<div className="nav-container">
						<div className="header-title"><h1>Our Services</h1></div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						content
					</div>
				</div>
			</div>
		)
	}
}

export default OurServices;