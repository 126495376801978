import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { auth } from '../firebase';
import * as routes from '../constants/routes';
// import { PasswordForgetForm } from './PasswordForget';
import logo from "../img/logo.png";

const SignInPage = ({ history }) => <SignInForm history={history} />

const byPropKey = (propertyName, value) => () => ({
	[propertyName]: value,
});

const INITIAL_STATE = {
	email: '',
	password: '',
	error: null,
	displayForgotPassword: false,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.toggleForgotPassword = this.toggleForgotPassword.bind(this);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    document.body.classList.add("login");
  }

componentWillUnmount() {
    document.body.classList.remove("login");
  }

  onSubmit = event => {
    const { email, password } = this.state;

    const { history } = this.props;

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        history.push(routes.HOME);
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
      });

    event.preventDefault();
  };

  toggleForgotPassword() {
    this.setState({
      displayForgotPassword: this.state.displayForgotPassword ? false : true
    });
  }

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return <div className="cont">
        <div className="form">
        	<form onSubmit={this.onSubmit}>
          <Link to="/"><img src={logo} alt="Storey Huish" width="100%" /></Link>
            <input value={email} onChange={event => this.setState(byPropKey("email", event.target.value))} type="text" placeholder="Email Address" className="user" />
            <input value={password} onChange={event => this.setState(byPropKey("password", event.target.value))} type="password" placeholder="Password" className="pass" />
            <button type="submit" className="login" disabled={isInvalid}>
            	Sign In
            </button>
            {/*<div
            	className="forgotPasswordLink"
            	onClick={() => this.toggleForgotPassword()}
            >
            	Forgot password?
			</div>*/}
            {error && <div className="alert alert-danger" role="alert">
                <strong>{error.message}</strong>
            </div>}
            {/*{this.state.displayForgotPassword && (
            	<div className="forgotPasswordForm">
                <PasswordForgetForm />
            	</div>
            )}*/}
        	</form>
        </div>
	</div>;
	}
}

export default SignInPage;

export {
	SignInForm,
};