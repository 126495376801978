import * as firebase from "firebase";

import {db} from './';

export const askForPermissioToReceiveNotifications = async () => {
	try {
		const messaging = firebase.messaging();
		await messaging.requestPermission();
		const token = await messaging.getToken();
		db.doCreateSubscriber(token);
		return token;
	} catch (error) {
		console.error(error);
	}
}