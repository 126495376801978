import * as firebase from 'firebase';

const config = {
    apiKey: "AIzaSyDqgMuo1Uc6I2GpmrL5iEBiQ7mr8o9-UgE",
    authDomain: "storey-huish.firebaseapp.com",
    databaseURL: "https://storey-huish.firebaseio.com",
    projectId: "storey-huish",
    storageBucket: "storey-huish.appspot.com",
    messagingSenderId: "927375345221"
  };

if (!firebase.apps.length) {
	firebase.initializeApp(config);
}
const db = firebase.database();
const auth = firebase.auth();
const messaging = firebase.messaging();

export { db, auth, messaging };