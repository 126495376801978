import { 
	FETCH_JOBS,
	FETCH_JOBS_SUCCESS, 
	FETCH_JOBS_ERROR
} from '../constants';

import { db } from "../firebase";

export const fetchJobsBegin = payload => ({ type: FETCH_JOBS });
export const fetchJobsSuccess = payload => ({ type: FETCH_JOBS_SUCCESS, payload });
export const fetchJobsError = payload => ({ type: FETCH_JOBS_ERROR, payload });

export function onGetJobs() {
	return dispatch => {
		dispatch(fetchJobsBegin());
		return db.onceGetJobs().then(snapshot => {
			dispatch(fetchJobsSuccess(snapshot.val()));
		}).catch(error => {
			console.log(error)
			dispatch(fetchJobsError(error))
		})
	};
}