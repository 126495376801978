export const LANDING = "/";
export const SIGN_IN = '/sign-in';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const CONTACT = '/contact';
export const SIGN_UP = '/sign-up';
export const OUR_SERVICES = '/our-services'
export const JOBS = '/jobs'
export const WHO_WE_ARE = '/about'
export const CLIENTS = "/clients";

export const KEY = "AAAA1-vgxkU:APA91bEKGrmUaHbZoE6DYG5scrXxIkxMALLXRhDqkYVwsO-PYdOrp0eJCEVHc3x6W6OAcVSbqY78jhj_I3KjAdK-KuoR6SWmFjmuFtUFg3N8ObXHCsb3RwLu2JXmoM-6L60HMO-Bdj2f";