import React, { Component } from 'react';
import { messaging } from "../firebase";
class Landing extends Component {

	componentWillMount() {
		messaging.askForPermissioToReceiveNotifications();
	}


	render() {
		return <div>
        <div className="header-img">
          <div className="nav-container">
            <div className="header-title">
              <h1>Charlies text title</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            content
          </div>
        </div>
      </div>;
	}
}

export default Landing;